import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { addAdvertisement } from '../../actions/advertisement';
import AdvertisementsActions from './AdvertisementsActions';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getSponsorships } from '../../actions/sponsorship';
import noImage from '../../img/no-image.png';
import pdfIcon from '../../img/pdf.png';
import DateButton from '../layout/DateButton';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import { useHistory } from 'react-router-dom';

 

const AddAdvertisement = ({ getSponsorships, setAlert, addAdvertisement, 
    auth: { user, loading }, sponsorship: { sponsorships }}) => {
  const [formData, setFormData] = useState({
    userId: user._id,
    presenter: user.username,
    title: '',
    description: '',
    selling_price: '',
    further_information: '',
    city: '',
    postal_code: ''
  });

  let history = useHistory();

  const imgRefFirst = useRef(null);
  const imgRefSecond = useRef(null);
  const imgRefThird = useRef(null);

  useEffect(() => {
    getSponsorships(user._id, false, false, false);
  }, [getSponsorships, user._id]);

  const { t } = useTranslation();


  const { userId, presenter, title, description, selling_price, /*offer_expiry_date, delivery_method,*/ further_information, city, postal_code } = formData;

  const [country, setCountry] = useState("");
  const [countryLabel, setCountryLabel] = useState();

  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [deliveryMethodLabel, setDeliveryMethodLabel] = useState();


  const [condition, setCondition] = useState();
  const [conditionLabel, setConditionLabel] = useState();


  const [category, setCategory] = useState("");
  const [categoryLabel, setCategoryLabel] = useState();

  const [subCategory, setSubCategory] = useState("");
  const [subCategoryLabel, setSubCategoryLabel] = useState();
  const [subCategories, setSubCategories] = useState([]);

  const [imgFirst, setImgFirst] = useState(noImage);
  const [imgSecond, setImgSecond] = useState(noImage);
  const [imgThird, setImgThird] = useState(noImage);
  const [imgToSendFirst, setImgToSendFirst] = useState(false);
  const [imgToSendSecond, setImgToSendSecond] = useState(false);
  const [imgToSendThird, setImgToSendThird] = useState(false);
  const [imgTemp, setImgTemp] = useState(false);



  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");

  const categories = {
    Home: [
      { value: '{"index": "Furnishings"}', label: t('Advertisements.Furnishings') },
      { value: '{"index": "Appliances"}', label: t('Advertisements.Appliances') },
      { value: '{"index": "DIY"}', label: t('Advertisements.DIY') },
      { value: '{"index": "Gardening"}', label: t('Advertisements.Gardening') },
      { value: '{"index": "Household_linen"}', label: t('Advertisements.Household_linen') },
      { value: '{"index": "Tableware"}', label: t('Advertisements.Tableware') },
      { value: '{"index": "Decoration"}', label: t('Advertisements.Decoration') },
    ],
    Multimedia: [
      { value: '{"index": "Computers"}', label: t('Advertisements.Computers') },
      { value: '{"index": "Telephony"}', label: t('Advertisements.Telephony') },
      { value: '{"index": "Image_sound"}', label: t('Advertisements.Image_sound') },
      { value: '{"index": "Console_video_games"}', label: t('Advertisements.Console_video_games') },
      { value: '{"index": "Accessories_peripherals"}', label: t('Advertisements.Accessories_peripherals') },
    ],
    Fashion: [
      { value: '{"index": "Clothing"}', label: t('Advertisements.Clothing') },
      { value: '{"index": "Shoes"}', label: t('Advertisements.Shoes') },
      { value: '{"index": "Watches_jewelry"}', label: t('Advertisements.Watches_jewelry') },
      { value: '{"index": "Accessories_luggage"}', label: t('Advertisements.Accessories_luggage') },
      { value: '{"index": "Baby"}', label: t('Advertisements.Baby') },
    ],
    Sports_leisure: [
      { value: '{"index": "Sports_equipment"}', label: t('Advertisements.Sports_equipment') },
      { value: '{"index": "Bikes"}', label: t('Advertisements.Bikes') },
      { value: '{"index": "Musical_instruments"}', label: t('Advertisements.Musical_instruments') },
      { value: '{"index": "Games_toys"}', label: t('Advertisements.Games_toys') },
      { value: '{"index": "Collection"}', label: t('Advertisements.Collection') },
    ],
    Vehicles: [
      { value: '{"index": "Cars"}', label: t('Advertisements.Cars') },
      { value: '{"index": "Utilities"}', label: t('Advertisements.Utilities') },
      { value: '{"index": "Motorcycles"}', label: t('Advertisements.Motorcycles') },
      { value: '{"index": "Caravanning"}', label: t('Advertisements.Caravanning') },
      { value: '{"index": "Boating"}', label: t('Advertisements.Boating') },
    ],
    Others: [
      { value: '{"index": "Services"}', label: t('Advertisements.Services') },
      { value: '{"index": "Pets"}', label: t('Advertisements.Pets') },
      { value: '{"index": "Donations"}', label: t('Advertisements.Donations') },
      { value: '{"index": "Sharing"}', label: t('Advertisements.Sharing') },
      { value: '{"index": "Miscellaneous"}', label: t('Advertisements.Miscellaneous') },
    ],
  };

  if (user && user.status === "C") {
    return <Redirect to="/dashboard" />;
  }
  
const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}

{imgTemp === "pdf" ? (<p>{t('Advertisements.Compress_pdf')} <a href={`https://shrinkpdf.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Advertisements.Here')}</a></p>)
: (imgTemp === "jpeg" || imgTemp === "jpg") ? (<p>{t('Advertisements.Compress_jpeg')} <a href={`https://compressjpeg.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Advertisements.Here')}</a></p>)
: imgTemp === "png" ? (<p>{t('Advertisements.Compress_png')} <a href={`https://compresspng.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Advertisements.Here')}</a></p>)
: ""}
</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }
</ModalFooter>
</Modal>



  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = async e => {
    e.preventDefault();
   /* if (!imgToSend) {
      setAlert('server_37_user', 'danger');
    } */
    /*if (!signature) {
      setAlert('server_38_user', 'danger');
    }*/
    addAdvertisement({ userId, presenter, title, description, selling_price, "condition": condition, "category": category, "sub_category": subCategory, "delivery_method": deliveryMethod, further_information, city, postal_code, country, imgToSendFirst, imgToSendSecond, imgToSendThird, history });

    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  };

 /* function uploadFile ({ target: { files } }) {
    console.log(files[0])
    let data = new FormData();
    data.append('file', files[0])
  } */

    function selectCountry (val) {
      setCountry(JSON.parse(val).index);
      setCountryLabel(val);
    }

    function selectCategory (val) {
      setCategory(JSON.parse(val).index);
      setCategoryLabel(val);
    }

  function selectSubCategory (val) {
    setSubCategory(JSON.parse(val).index);
    setSubCategoryLabel(val);
  }

  const dropDownSelectedCategory = event => {
    if (event && JSON.parse(event).index) {
    selectCategory(event)
    setSubCategories(categories[JSON.parse(event).index] || []);
    setSubCategory("");
    setSubCategoryLabel(null);
    }
  }

  const dropDownSelectedSubCategory = event => {
    if (event && JSON.parse(event).index) {
    selectSubCategory(event)
    }
  }

  const dropDownSelected = event => {
    if (event && JSON.parse(event).index) {
    selectCountry(event)
    }
  }

  function selectDeliveryMethod (val) {
    setDeliveryMethod(JSON.parse(val).index);
    setDeliveryMethodLabel(val);
  }

  const dropDownSelectedDeliveryMethod = event => {
    if (event && JSON.parse(event).index) {
    selectDeliveryMethod(event)
    }
  }

  function selectCondition (val) {
    setCondition(JSON.parse(val).index);
    setConditionLabel(val);
  }

  const dropDownSelectedCondition = event => {
    if (event && event && JSON.parse(event).index) {
    selectCondition(event)
    }
  }

function handleAttachmentImgFirstChange  (changeEvent) {
  if (changeEvent.currentTarget.files[0]) {
  if (changeEvent.currentTarget.files[0].size > 300300) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Advertisements.Warning'));
    setModalBody(t('Advertisements.File_too_large_0_3_MB') + "\n");
    setModalFirstButton(t('Advertisements.Ok'));
    setModalIsOpen(true);
    imgRefFirst.current.value = null;
  }
  else{
    if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
      setImgFirst(pdfIcon);
    }
    else {
      setImgFirst(URL.createObjectURL(changeEvent.currentTarget.files[0]));
    }
    setImgToSendFirst(changeEvent.currentTarget.files[0]);
  } 
}
}

function handleAttachmentImgSecondChange  (changeEvent) {
  if (changeEvent.currentTarget.files[0]) {
  if (imgToSendFirst && imgToSendSecond && changeEvent.currentTarget.files[0].size > 300300) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Advertisements.Warning'));
    setModalBody(t('Advertisements.File_too_large_0_3_MB') + "\n");
    setModalFirstButton(t('Advertisements.Ok'));
    setModalIsOpen(true);
    imgRefSecond.current.value = null;
  }
  else{
  if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
    setImgSecond(pdfIcon);
  }
  else {
    setImgSecond(URL.createObjectURL(changeEvent.currentTarget.files[0]));
  }
  setImgToSendSecond(changeEvent.currentTarget.files[0]);
}
  }
}

function handleAttachmentImgThirdChange  (changeEvent) {
    if (changeEvent.currentTarget.files[0]) {
    if (changeEvent.currentTarget.files[0].size > 300300) {
      setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
      setModalTitle(t('Advertisements.Warning'));
      setModalBody(t('Advertisements.File_too_large_0_3_MB') + "\n");
      setModalFirstButton(t('Advertisements.Ok'));
      setModalIsOpen(true);
      imgRefThird.current.value = null;
    }
    else{
    if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
      setImgThird(pdfIcon);
    }
    else {
      setImgThird(URL.createObjectURL(changeEvent.currentTarget.files[0]));
    }
    setImgToSendThird(changeEvent.currentTarget.files[0]);
  }
    }
  }




    return loading && user === null ? (
        <Spinner />
      ) : (

    <Fragment>

<AdvertisementsActions />
<br/><br/>
<div className="scrollme"> 
      <h1 className='large text-primary'>{t('Advertisements.Add_advertisement')}</h1>
      {/*<p className='lead'>
        <i className='fas fa-user' /> {t('Advertisements.Create_new_advertisement')}
      </p>*/}
      <form className='form' onSubmit={e => onSubmit(e)}>
      <p className="h1">I. {t('Advertisements.General_information')}</p>
        <div className='form-group'>
          <input
            type='text'
            readOnly = "readOnly"
            placeholder={t('Advertisements.Presenter')}
            name='presenter'
        //    value={username.replace(/\*/g, '')}
            value={presenter.replace(/[^a-zA-Z ]/g, '').trim()}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
            <input
              type='text'
              placeholder={t('Advertisements.Title')+"*"}
              name='title'
              value={title}
              onPaste={e=>{
                e.preventDefault();
                return false
              }}
              onChange={e => onChange(e)}
            />
            </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Advertisements.Description')+"*"}
            name='description'
            value={description}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
          <small className='form-text'>
          </small>
          </div>
        <div className='form-group'>
            <input
              type='text'
              placeholder={t('Advertisements.Selling_price')+"*"}
              name='selling_price'
              value={selling_price.replace(/[^0-9,.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(,.*?),/g, '$1').replace(/,/g, '.')}
              onPaste={e=>{
                e.preventDefault();
                return false
              }}
              onChange={e => onChange(e)}
            />
            </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Advertisements.Further_information')}
            name='further_information'
            value={further_information.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
<select className="form-control" name="action" value={conditionLabel} onChange={e => dropDownSelectedCondition(e.target.value)}>
<option value="">{t('Advertisements.Condition')}</option>
             <option value= {'{"index": "Mint_condition"}'}>{t('Advertisements.Mint_condition')}</option>
             <option value= {'{"index": "Very_good_condition"}'}>{t('Advertisements.Very_good_condition')}</option>
             <option value= {'{"index": "Good_condition"}'}>{t('Advertisements.Good_condition')}</option>
             <option value= {'{"index": "Satisfactory_condition"}'}>{t('Advertisements.Satisfactory_condition')}</option>
             <option value= {'{"index": "For_parts"}'}>{t('Advertisements.For_parts')}</option>
           </select>
        </div>

        <div className='form-group'>
<select className="form-control" name="action" value={categoryLabel} onChange={e => dropDownSelectedCategory(e.target.value)}>
<option value="">{t('Advertisements.Category')}</option>
             <option value= {'{"index": "Home"}'}>{t('Advertisements.Home')}</option>
             <option value= {'{"index": "Fashion"}'}>{t('Advertisements.Fashion')}</option>
             <option value= {'{"index": "Multimedia"}'}>{t('Advertisements.Multimedia')}</option>
             <option value= {'{"index": "Sports_leisure"}'}>{t('Advertisements.Sports_leisure')}</option>
             <option value= {'{"index": "Vehicles"}'}>{t('Advertisements.Vehicles')}</option>
             <option value= {'{"index": "Others"}'}>{t('Advertisements.Others')}</option>
           </select>
        </div>


        <div className='form-group'>
        <select className="form-control" name="action" value={subCategoryLabel} onChange={e => dropDownSelectedSubCategory(e.target.value)}>   
            <option value="">{t('Advertisements.Sub_category')}</option>
            {
            subCategories.map((subCategory) => (
            <option key={subCategory.value} value={subCategory.value}>
              {subCategory.label}
              </option>
              ))}
           </select>
        </div>

        <div className='form-group'>
        <select className="form-control" name="action" value={deliveryMethodLabel} onChange={e => dropDownSelectedDeliveryMethod(e.target.value)}>
             <option value="">{t('Advertisements.Delivery_method')}</option>
             <option value= {'{"index": "Hand_delivery_only"}'}>{t('Advertisements.Hand_delivery_only')}</option>
             <option value= {'{"index": "Hand_delivery"}'}>{t('Advertisements.Hand_delivery')}</option>
           </select>
        </div>


        <p className="h1">II. {t('Advertisements.Location')}</p>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Advertisements.City_locality')+"*"}
            name='city'
            value={city.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Advertisements.Postal_code')+"*"}
            name='postal_code'
            value={postal_code.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>



    <div className='form-group'>



<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelected(e.target.value)}>
<option value="">{t('Advertisements.Select_country')}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
             <option value= {'{"index": "GP"}'}>{t('Country.GP')}</option>
             <option value= {'{"index": "GF"}'}>{t('Country.GF')}</option>
             <option value= {'{"index": "MQ"}'}>{t('Country.MQ')}</option>
             <option value= {'{"index": "RE"}'}>{t('Country.RE')}</option>
           </select>
        </div>

        <p className="h1">III. {t('Advertisements.Pictures')}</p>
        <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>


 
      <img src={imgFirst} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: 'block',
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor= "file_first" className="btn btn-secondary">{t('Advertisements.Choose_image') + " (1)"}</label>
          <input
           ref={imgRefFirst}
            type='file'
            id='file_first'
            name='file_first'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgFirstChange}
          />   

<p>
       {imgToSendFirst.name}
      </p>
          <br/>
<img src={imgSecond} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: "block",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor="file_second" className="btn btn-secondary">{t('Advertisements.Choose_image') + " (2)"}</label>
          <input
           ref={imgRefSecond}
            type='file'
            id='file_second'
            name='file_second'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgSecondChange}
          /> 
          <p>
          {imgToSendSecond.name}
         </p>
          <br/>
         <img src={imgThird} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: "block",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor="file_third" className="btn btn-secondary">{t('Advertisements.Choose_image') + " (3)"}</label>
          <input
           ref={imgRefThird}
            type='file'
            id='file_third'
            name='file_third'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgThirdChange}
          /> 
          <p>
          {imgToSendThird.name}
         </p>

    </div>
    
    <br/>
        <input type='submit' className='btn btn-block btn-primary' value={t('Advertisements.Confirm')} />
      </form>

      {showModal}
      </div>
    </Fragment>
  );
};



      /*
        <CountryDropdown
          defaultOptionLabel= {t('Sponsorship.Select_country')}
          value={country}
          onChange={(val) => selectCountry(val)} />
           */

  AddAdvertisement.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  addAdvertisement: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  sponsorship: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    sponsorship: state.sponsorship,
    getSponsorships: PropTypes.func.isRequired
});

export default connect(
  mapStateToProps,
  { getSponsorships, setAlert, addAdvertisement }
)(AddAdvertisement);
