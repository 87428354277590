import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { getSponsorships, getAllUsers } from '../../../actions/sponsorship';
import { getAllTransactions, getInvoice } from '../../../actions/transaction';
import Pagination from '../../layout/Pagination';
import { Redirect } from 'react-router-dom';
import csvIcon from '../../../img/csv.png';
import { useTranslation } from 'react-i18next';


const TransactionAdmin = ({ getAllTransactions, getSponsorships, getAllUsers, getInvoice, auth: { user, theme }, transaction: { all_transactions }, sponsorship: { loading, all_users } }) => {


  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(10);

 // const [criteria, setCriteria] = useState("A");
  const [searchCriteria, setSearchCriteria] = useState("R");
  const [searchField, setSearchField] = useState("");

  const [country, setCountry] = useState("ALL");
  const [countryLabel, setCountryLabel] = useState();


  const [allRecipesChange, setAllRecipesChange] = useState(true);
  const [membershipProvisionChange, setMembershipProvisionChange] = useState(false);
  const [monthlySubscriptionChange, setMonthlySubscriptionChange] = useState(false);
  const [repositioningCostsChange, setRepositioningCostsChange] = useState(false);
  const [annualSubscriptionChange, setAnnualSubscriptionChange] = useState(false);

  const [allExpensesChange, setAllExpensesChange] = useState(true);
  const [participationIncomeChange, setParticipationIncomeChange] = useState(false);
  const [affiliationFeeChange, setAffiliationFeeChange] = useState(false);
  const [trainingBonusChange, setTrainingBonusChange] = useState(false);
  const [matrixCommissionChange, setMatrixCommissionChange] = useState(false);
  const [repositioningBonusChange, setRepositioningBonusChange] = useState(false);

  
  const [allOthersChange, setAllOthersChange] = useState(true);
  const [vatChange, setVatChange] = useState(false);
  const [transferChange, setTransferChange] = useState(false);
  const [cancellationChange, setCancellationChange] = useState(false);
  const [refundChange, setRefundChange] = useState(false);
  const [balanceAccountChange, setBalanceAccountChange] = useState(false);

 /* useEffect(() => {
    document.body.style = 'background: #EDAE6A'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      document.body.style = 'background: #D3D3D3'; //#DCDCDC
    }
  }, []); */

  useEffect(() => {
    getAllUsers(user._id);
  }, [getAllUsers, user._id]);

  useEffect(() => {
    getAllTransactions(user._id);
  }, [getAllTransactions, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();

  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }



  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  var currentTransactions = [];
  var sortedTransactions = [];
  if (all_transactions && all_transactions.length > 0) {
    let tempData = [...all_transactions].filter(item => ![6, 9, 11].includes(item.transaction_type)).reverse();
    for (var i = 0; i < tempData.length; i++) {

      if (tempData[i].transaction_type === 0 || tempData[i].transaction_type === 2 || tempData[i].transaction_type === 3 || tempData[i].transaction_type === 13) {
          if (tempData[i].country === "BE") {
            tempData[i].vat = parseFloat((tempData[i].amount / 121) * 21).toFixed(2)
        }
        else if (tempData[i].country === "FR") {
          tempData[i].vat = parseFloat((tempData[i].amount / 120) * 20).toFixed(2)
        }
        else if (tempData[i].country === "IT") {
          tempData[i].vat = parseFloat((tempData[i].amount / 122) * 22).toFixed(2)
        }
        else if (tempData[i].country === "LU") {
          tempData[i].vat = parseFloat((tempData[i].amount / 117) * 17).toFixed(2)
        }
        else if (tempData[i].country === "CH") {
          tempData[i].vat = parseFloat((tempData[i].amount / 107.7) * 7.7).toFixed(2)
        }
        else {
          tempData[i].vat = parseFloat((tempData[i].amount / 120) * 20).toFixed(2)
        }
      }
      else {
        tempData[i].vat = parseFloat(0).toFixed(2)
      }

      if (sortedTransactions[sortedTransactions.length - 1]) {
        tempData[i].number = sortedTransactions[sortedTransactions.length - 1].number + 1;
      }
      else {
        tempData[i].number = 1
      }


      if ((allRecipesChange && (tempData[i].transaction_type === 0 || tempData[i].transaction_type === 2 || tempData[i].transaction_type === 13 || tempData[i].transaction_type === 3))
       || (allExpensesChange && (tempData[i].transaction_type === 1 || tempData[i].affiliation_bonus_quantity > 0 || tempData[i].affiliation_bonus_quantity > 0 || tempData[i].matrix_commission_quantity > 0 || tempData[i].repositioning_costs_quantity > 0)) ||
       (allOthersChange && (tempData[i].transaction_type === 4 || tempData[i].transaction_type === 5 || tempData[i].transaction_type === 6  || tempData[i].transaction_type === 7  || tempData[i].transaction_type === 8  || tempData[i].transaction_type === 9  || tempData[i].transaction_type === 10 || tempData[i].transaction_type === 11 || tempData[i].transaction_type === 12)) ||
        (membershipProvisionChange && tempData[i].transaction_type === 0) || (monthlySubscriptionChange && tempData[i].transaction_type === 2) || (repositioningCostsChange && tempData[i].transaction_type === 13) || (annualSubscriptionChange && tempData[i].transaction_type === 3)
         || (participationIncomeChange && tempData[i].transaction_type === 1)
         || (affiliationFeeChange && tempData[i].affiliation_bonus_quantity > 0)
         || (trainingBonusChange && tempData[i].affiliation_bonus_quantity > 0)
         || (matrixCommissionChange && tempData[i].matrix_commission_quantity > 0)
         || (repositioningBonusChange && tempData[i].repositioning_costs_quantity > 0)
         || (vatChange && (tempData[i].transaction_type === 0 || tempData[i].transaction_type === 2 || tempData[i].transaction_type === 3 || tempData[i].transaction_type === 13))
         || (transferChange && (tempData[i].transaction_type === 4 || tempData[i].transaction_type === 8))
    //     || (cancellationChange && (tempData[i].transaction_type === 6 || tempData[i].transaction_type === 9 || tempData[i].transaction_type === 11))
         || (refundChange && (tempData[i].transaction_type === 7 || tempData[i].transaction_type === 12))
         || (balanceAccountChange && (tempData[i].transaction_type === 5 || tempData[i].transaction_type === 10))) {

  /*  if ((criteria === "A") || (criteria === "I" && (tempData[i].transaction_type === 0 || tempData[i].transaction_type === 2 || tempData[i].transaction_type === 3))
    || (criteria === "P" && tempData[i].transaction_type === 1)
    || (criteria === "T" && (tempData[i].transaction_type === 8 || tempData[i].transaction_type === 10))
    || (criteria === "C" && tempData[i].transaction_type === 6)
    || (criteria === "R" && tempData[i].transaction_type === 7))
    { */
      if (country === "ALL" || country === tempData[i].country) {
      if (searchField) {
           if (searchCriteria === "U") {
        if (tempData[i].username.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedTransactions.push(tempData[i]);
        }
      }
        else if (searchCriteria === "R") {
            if (tempData[i].new_id.toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedTransactions.push(tempData[i]);
          }
        }
      else if (searchCriteria === "D") {

        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }

      if (finalDateDay.startsWith(searchField)) {
        sortedTransactions.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
     

    if (dateMonth.startsWith(searchField)) {
        sortedTransactions.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]


  if (dateYear.startsWith(searchField)) {
    sortedTransactions.push(tempData[i]);
  }
}
      }
      else {
        sortedTransactions.push(tempData[i]);
      }
 //   }
    }
    }
  }


    currentTransactions = sortedTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
  }

  function exportCSV()
  {
    var csvRow = [];
    var sum = 0;
    var sum_vat = 0;

    var A = [[t('AdminTransaction.Num'), t('AdminTransaction.Ref'), t('AdminTransaction.Date'), t('AdminTransaction.Description'), t('AdminTransaction.Username'), t('AdminTransaction.Status'), t('AdminTransaction.Country'), t('AdminTransaction.Amount'),  t('AdminTransaction.Vat')]]
  //  A.push([t('AdminTransaction.Num'), t('AdminTransaction.Ref'), t('AdminTransaction.Date'), t('AdminTransaction.Description'), t('AdminTransaction.Country'), t('AdminTransaction.Username'), t('AdminTransaction.Amount'),  t('AdminTransaction.Vat')])
  if (all_transactions && all_transactions.length > 0) {
    for (let item = 0; item < sortedTransactions.length; item++) {
      const user = all_users?.find(user => user._id === sortedTransactions[item].user);
      A.push([sortedTransactions.length - sortedTransactions[item].number + 1, sortedTransactions[item].new_id, (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? sortedTransactions[item].date_creation.substring(0, 10).split("-")[2] + '/' + sortedTransactions[item].date_creation.substring(0, 10).split("-")[1] + '/' + sortedTransactions[item].date_creation.substring(0, 10).split("-")[0] : sortedTransactions[item].date_creation.substring(0, 10).split("-")[1] + '/' + sortedTransactions[item].date_creation.substring(0, 10).split("-")[2] + '/' + sortedTransactions[item].date_creation.substring(0, 10).split("-")[0], (sortedTransactions[item].transaction_type === 0 ? t('AdminTransaction.Membership_provision') :
      sortedTransactions[item].transaction_type === 1 ? t('AdminTransaction.Participation_income') :
      sortedTransactions[item].transaction_type === 2 ? t('AdminTransaction.Monthly_subscription') :
      sortedTransactions[item].transaction_type === 3 ? t('AdminTransaction.Annual_subscription') :
      sortedTransactions[item].transaction_type === 4 ? t('AdminTransaction.Transfer') :
      sortedTransactions[item].transaction_type === 5 ? t('AdminTransaction.Balance_account') :
      sortedTransactions[item].transaction_type === 6 ? t('AdminTransaction.Cancellation_affiliation_bonus') :
      sortedTransactions[item].transaction_type === 7 ? t('AdminTransaction.Affiliate_premium_refund') :
      sortedTransactions[item].transaction_type === 8 ? t('AdminTransaction.Transfer_accepted') :
      sortedTransactions[item].transaction_type === 9 ? t('AdminTransaction.Transfer_refused') :
      sortedTransactions[item].transaction_type === 10 ? t('AdminTransaction.Balance_account_accepted') :
      sortedTransactions[item].transaction_type === 11 ? t('AdminTransaction.Balance_account_refused') :
      sortedTransactions[item].transaction_type === 12 ? t('AdminTransaction.Transfer_refused_refund') :
      sortedTransactions[item].transaction_type === 13 ? t('AdminTransaction.Repositioning_costs') : ""), sortedTransactions[item].username,
      (user?.status === "C" ? t('AdminSponsorship.Candidate') :
      user?.status === "D" && user?.deletion_cause === "Bad_behaviour" ? t('AdminSponsorship.Removed') :
      user?.status === "D" ? t('AdminSponsorship.Deleted') :
      user?.status === "J" ? t('AdminSponsorship.Joker') :
      user?.status === "A" ? t('AdminSponsorship.Member') :
      user?.status === "B" ? t('AdminSponsorship.Candidate_member') :
      user?.status.charAt(0) === "M" ? t('AdminSponsorship.Actif_member') + user?.status.substring(1) : "" )
      , t(`Country.${sortedTransactions[item].country}`), -parseFloat(sortedTransactions[item].amount).toFixed(2), -parseFloat(sortedTransactions[item].vat).toFixed(2)])
      sum = parseFloat(sum - sortedTransactions[item].amount).toFixed(2) ;
      sum_vat = parseFloat(sum_vat - sortedTransactions[item].vat).toFixed(2) ;
    }
    A.push([],[t('AdminTransaction.Total'), '', '', '', '', '', '', sum, sum_vat])
    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(";"))
    }
    var csvString=csvRow.join("%0A");

    var a = document.createElement("a");
    a.href='data:attachment/csv,' + csvString;
    a.target="_Blank";
    a.download= t('AdminTransaction.Activities') + (searchField !== "" ? (" " + searchField) : "") +".csv";
    document.body.appendChild(a);
    a.click();
   // console.warn(csvString)
  }
  }

  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedTransactions.length / transactionsPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedTransactions.length / transactionsPerPage));



 /* function handleCriteriaChange (changeEvent) {
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
} */

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "U" || changeEvent.currentTarget.value === "C" || changeEvent.currentTarget.value === "R") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}

function selectCountry (val) {
  // setCountry(t(`Country.${JSON.parse(val).index}`));
  setCountry(JSON.parse(val).index);
   setCountryLabel(val);
 }

 const dropDownSelected = event => {
   if (JSON.parse(event).index) {
   selectCountry(event)
   }
 }


 function handleAllRecipesChange () {
  setAllRecipesChange(!allRecipesChange);
  setMembershipProvisionChange(false);
  setMonthlySubscriptionChange(false);
  setRepositioningCostsChange(false);
  setAnnualSubscriptionChange(false);
  setCurrentPage(1);
}

function handleMembershipProvisionChange () {
  setAllRecipesChange(false);
  setMembershipProvisionChange(!membershipProvisionChange);
  setCurrentPage(1);
}
      
function handleMonthlySubscriptionChange () {
  setAllRecipesChange(false);
  setMonthlySubscriptionChange(!monthlySubscriptionChange);
  setCurrentPage(1);
}

function handleRepositioningCostsChange () {
  setAllRecipesChange(false);
  setRepositioningCostsChange(!repositioningCostsChange);
  setCurrentPage(1);
}

function handleAnnualSubscriptionChange () {
  setAllRecipesChange(false);
  setAnnualSubscriptionChange(!annualSubscriptionChange);
  setCurrentPage(1);
}


function handleAllExpensesChange () {
  setAllExpensesChange(!allExpensesChange);
  setParticipationIncomeChange(false);
  setAffiliationFeeChange(false);
  setTrainingBonusChange(false);
  setMatrixCommissionChange(false);
  setRepositioningBonusChange(false);
  setCurrentPage(1);
}

function handleParticipationIncomeChange () {
  setAllExpensesChange(false);
  setParticipationIncomeChange(!participationIncomeChange);
  setCurrentPage(1);
}

function handleAffiliationFeeChange () {
  setAllExpensesChange(false);
  setAffiliationFeeChange(!affiliationFeeChange);
  setCurrentPage(1);
}

function handleTrainingBonusChange () {
  setAllExpensesChange(false);
  setTrainingBonusChange(!trainingBonusChange);
  setCurrentPage(1);
}

function handleMatrixCommissionChange () {
  setAllExpensesChange(false);
  setMatrixCommissionChange(!matrixCommissionChange);
  setCurrentPage(1);
}

function handleRepositioningBonusChange () {
  setAllExpensesChange(false);
  setRepositioningBonusChange(!repositioningBonusChange);
  setCurrentPage(1);
}


function handleAllOthersChange () {
  setAllOthersChange(!allOthersChange);
  setVatChange(false);
  setTransferChange(false);
  setCancellationChange(false);
  setRefundChange(false);
  setBalanceAccountChange(false);
  setCurrentPage(1);
}

function handleVatChange () {
  setAllOthersChange(false);
  setVatChange(!vatChange);
  setCurrentPage(1);
}

function handleTransferChange () {
  setAllOthersChange(false);
  setTransferChange(!transferChange);
  setCurrentPage(1);
}

function handleCancellationChange () {
  setAllOthersChange(false);
  setCancellationChange(!cancellationChange);
  setCurrentPage(1);
}

function handleRefundChange () {
  setAllOthersChange(false);
  setRefundChange(!refundChange);
  setCurrentPage(1);
}

function handleBalanceAccountChange () {
  setAllOthersChange(false);
  setBalanceAccountChange(!balanceAccountChange);
  setCurrentPage(1);
}



      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('AdminTransaction.Activity_table')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>



<div className="scrollme"> 

<div style={{width: 50, height: 'auto'}}>
    <button style={{backgroundColor: "#D3D3D3"}}><img src={csvIcon} alt="Csv icon"  className="img-fluid" onClick={() => exportCSV()} /></button>
    </div>

<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('AdminTransaction.Search_by') + " " + (searchCriteria === "U" ? t('AdminTransaction.Username').toLowerCase() : searchCriteria === "R" ? t('AdminTransaction.Reference').toLowerCase() : searchCriteria === "D" ? t('AdminTransaction.Day_text').toLowerCase() : searchCriteria === "M" ? t('AdminTransaction.Month_text').toLowerCase() : searchCriteria === "Y" ? t('AdminTransaction.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransaction.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="R" 
         id="ref"
         className="custom-control-input"
                      checked={searchCriteria === 'R'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="ref">
          {t('AdminTransaction.Ref')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="U" 
         id="username"
         className="custom-control-input"
                      checked={searchCriteria === 'U'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="username">
          {t('AdminTransaction.Username')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('AdminTransaction.Day')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('AdminTransaction.Month')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('AdminTransaction.Year')}
      </label>
      </div>

    </div>

    <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransaction.Country')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>
<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelected(e.target.value)}>
<option value= {'{"index": "ALL"}'}>{t('AdminTransaction.All_countries')}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
           </select>
        </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransaction.Recipes').toUpperCase() + " - " + t('AdminTransaction.Type')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

   {/*    <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="A" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'A'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('AdminTransaction.All')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="I" 
         id="invoice"
         className="custom-control-input"
                      checked={criteria === 'I'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="invoice">
          {t('AdminTransaction.Invoice')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="P" 
         id="participation"
         className="custom-control-input"
                      checked={criteria === 'P'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="participation">
          {t('AdminTransaction.Participation')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="T" 
         id="transfer"
         className="custom-control-input"
                      checked={criteria === 'T'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="transfer">
          {t('AdminTransaction.Transfer')}
      </label>
      </div>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="C" 
         id="cancellation"
         className="custom-control-input"
                      checked={criteria === 'C'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="cancellation">
          {t('AdminTransaction.Cancellation')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="R" 
         id="refund"
         className="custom-control-input"
                      checked={criteria === 'R'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="refund">
          {t('AdminTransaction.Refund')}
      </label>
      </div>*/}

<div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="all_recipes"
         className="custom-control-input"
                      checked={allRecipesChange}
                      onChange={handleAllRecipesChange} />
                      <label className="custom-control-label" for="all_recipes">
          {t('AdminTransaction.All')}
      </label>
      </div>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="annual_subscription"
         className="custom-control-input"
                      checked={annualSubscriptionChange}
                      onChange={handleAnnualSubscriptionChange} />
                      <label className="custom-control-label" for="annual_subscription">
          {t('AdminTransaction.Annual_subscription')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="membership_provision"
         className="custom-control-input"
                      checked={membershipProvisionChange}
                      onChange={handleMembershipProvisionChange} />
                      <label className="custom-control-label" for="membership_provision">
          {t('AdminTransaction.Membership_provision')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="monthly_subscription"
         className="custom-control-input"
                      checked={monthlySubscriptionChange}
                      onChange={handleMonthlySubscriptionChange} />
                      <label className="custom-control-label" for="monthly_subscription">
          {t('AdminTransaction.Monthly_subscription')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="repositioning_costs"
         className="custom-control-input"
                      checked={repositioningCostsChange}
                      onChange={handleRepositioningCostsChange} />
                      <label className="custom-control-label" for="repositioning_costs">
          {t('AdminTransaction.Repositioning_costs')}
      </label>
      </div>

    </div>

    <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransaction.Expenses').toUpperCase() + " - " + t('AdminTransaction.Type')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="all_expenses"
         className="custom-control-input"
                      checked={allExpensesChange}
                      onChange={handleAllExpensesChange} />
                      <label className="custom-control-label" for="all_expenses">
          {t('AdminTransaction.All')}
      </label>
      </div>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="participation_income"
         className="custom-control-input"
                      checked={participationIncomeChange}
                      onChange={handleParticipationIncomeChange} />
                      <label className="custom-control-label" for="participation_income">
          {t('AdminTransaction.Participation_income')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="affiliation_fee"
         className="custom-control-input"
                      checked={affiliationFeeChange}
                      onChange={handleAffiliationFeeChange} />
                      <label className="custom-control-label" for="affiliation_fee">
          {t('AdminTransaction.Affiliation_fee')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="training_bonus"
         className="custom-control-input"
                      checked={trainingBonusChange}
                      onChange={handleTrainingBonusChange} />
                      <label className="custom-control-label" for="training_bonus">
          {t('AdminTransaction.Training_bonus')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="matrix_commission"
         className="custom-control-input"
                      checked={matrixCommissionChange}
                      onChange={handleMatrixCommissionChange} />
                      <label className="custom-control-label" for="matrix_commission">
          {t('AdminTransaction.Matrix_commission')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="repositioning_bonus"
         className="custom-control-input"
                      checked={repositioningBonusChange}
                      onChange={handleRepositioningBonusChange} />
                      <label className="custom-control-label" for="repositioning_bonus">
          {t('AdminTransaction.Repositioning_bonus')}
      </label>
      </div>

    </div>



    <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransaction.Others').toUpperCase() + " - " + t('AdminTransaction.Type')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="all_others"
         className="custom-control-input"
                      checked={allOthersChange}
                      onChange={handleAllOthersChange} />
                      <label className="custom-control-label" for="all_others">
          {t('AdminTransaction.All')}
      </label>
      </div>



      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="vat"
         className="custom-control-input"
                      checked={vatChange}
                      onChange={handleVatChange} />
                      <label className="custom-control-label" for="vat">
          {t('AdminTransaction.Vat').toUpperCase()}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="transfer"
         className="custom-control-input"
                      checked={transferChange}
                      onChange={handleTransferChange} />
                      <label className="custom-control-label" for="transfer">
          {t('AdminTransaction.Transfer')}
      </label>
      </div>


     {/*<div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="cancellation"
         className="custom-control-input"
                      checked={cancellationChange}
                      onChange={handleCancellationChange} />
                      <label className="custom-control-label" for="cancellation">
          {t('AdminTransaction.Cancellation')}
      </label>
      </div>*/}


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="refund"
         className="custom-control-input"
                      checked={refundChange}
                      onChange={handleRefundChange} />
                      <label className="custom-control-label" for="refund">
          {t('AdminTransaction.Refund')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="balance_account"
         className="custom-control-input"
                      checked={balanceAccountChange}
                      onChange={handleBalanceAccountChange} />
                      <label className="custom-control-label" for="balance_account">
          {t('AdminTransaction.Balance_account')}
      </label>
      </div>


    </div>
    
<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      {/*<th scope="col" colSpan={9}>{t('AdminTransaction.Table_activities').toUpperCase()}</th>
      <th scope="col" colSpan={3} style={{borderLeft: "thick double #398DE7"}}>{t('AdminTransaction.Digital_wallets').toUpperCase()}</th>*/}
    </tr>
  <tr>
      <th scope="col">{t('AdminTransaction.Num')}</th>
      <th scope="col">{t('AdminTransaction.Ref')}</th>
      <th scope="col">{t('AdminTransaction.Date')}</th>
      <th scope="col">{t('AdminTransaction.Description')}</th>
      <th scope="col">{t('AdminTransaction.Username')}</th>
      <th scope="col">{t('AdminTransaction.Status')}</th>
      <th scope="col">{t('AdminTransaction.Country')}</th>
      <th scope="col">{t('AdminTransaction.Amount')}</th>
      <th scope="col">{t('AdminTransaction.Vat')}</th>
      <th scope="col">{t('AdminTransaction.Action')}</th>
  {/*    <th scope="col" style={{borderLeft: "thick double #398DE7"}}>{t('AdminTransaction.Amount')}</th>
      <th scope="col">{t('AdminTransaction.Balance')}</th>
      <th scope="col">{t('AdminTransaction.Action')}</th>*/} 
      </tr>
  </thead>
  <tbody>


  {!all_transactions ? 
          <tr>
          <th colSpan={10}>{t('AdminTransaction.Calculating') + " ..."}</th>
        </tr>
      :
      sortedTransactions.length === 0 ?
      <tr>
          <th colSpan={10}>{t('AdminTransaction.No_activities')}</th>
        </tr>
       :
      currentTransactions.map(( get_transaction, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))
     return (
    <tr key={index}>

    <td>{sortedTransactions.length - get_transaction.number + 1 }</td>
    <td>{get_transaction.new_id}</td>
    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_transaction.date_creation.substring(0, 10).split("-")[2] + '/' + get_transaction.date_creation.substring(0, 10).split("-")[1] + '/' + get_transaction.date_creation.substring(0, 10).split("-")[0] : get_transaction.date_creation.substring(0, 10).split("-")[1] + '/' + get_transaction.date_creation.substring(0, 10).split("-")[2] + '/' + get_transaction.date_creation.substring(0, 10).split("-")[0]}</td>
    <td>
      {(get_transaction.transaction_type === 0 ? t('AdminTransaction.Membership_provision') :
      get_transaction.transaction_type === 1 ? t('AdminTransaction.Participation_income') :
      get_transaction.transaction_type === 2 ? t('AdminTransaction.Monthly_subscription') :
      get_transaction.transaction_type === 3 ? t('AdminTransaction.Annual_subscription') :
      get_transaction.transaction_type === 4 ? t('AdminTransaction.Transfer') :
      get_transaction.transaction_type === 5 ? t('AdminTransaction.Balance_account') :
      get_transaction.transaction_type === 6 ? t('AdminTransaction.Cancellation_affiliation_bonus') :
      get_transaction.transaction_type === 7 ? t('AdminTransaction.Affiliate_premium_refund') :
      get_transaction.transaction_type === 8 ? t('AdminTransaction.Transfer_accepted') :
      get_transaction.transaction_type === 9 ? t('AdminTransaction.Transfer_refused') :
      get_transaction.transaction_type === 10 ? t('AdminTransaction.Balance_account_accepted') :
      get_transaction.transaction_type === 11 ? t('AdminTransaction.Balance_account_refused') :
      get_transaction.transaction_type === 12 ? t('AdminTransaction.Transfer_refused_refund') :
      get_transaction.transaction_type === 13 ? t('AdminTransaction.Repositioning_costs') : "" )}</td>
      
      <td>{get_transaction.username}</td>
      <td>
      {(() => {
    const user = all_users?.find(user => user._id === get_transaction.user);
    return user?.status === "C" 
      ? t('AdminTransaction.Candidate') 
      : user?.status === "J" 
      ? t('AdminTransaction.Joker') :
      user?.status === "A" ?  t('AdminTransaction.Member') :
      user?.status === "B" ?  t('AdminTransaction.Candidate_member') :
      user?.status === "D" ?  t('AdminTransaction.Deleted') :
      user?.status.charAt(0) === "M" ? t('AdminTransaction.Actif_member') + user?.status.substring(1)
      : "";
      })()}
      </td>
      <td>{t(`Country.${get_transaction.country}`)}</td>
      <td><font color={/*#03925e*/get_transaction.amount > 0 ? "red" : ""}>{(-get_transaction.amount).toFixed(2) + "€"}</font></td>
      <td>
      {(-get_transaction.vat).toFixed(2) + "€"}
      </td>
      {/*<td style={{borderLeft: "thick double #398DE7"}}><font color={get_transaction.amount >= 0 ? "" : "red"}>{(get_transaction.amount).toFixed(2) + "€"}</font></td>
      <td>
      <strong>{get_transaction.balance.toFixed(2) + "€"}</strong>
      </td>*/}
      <td>
      {get_transaction.transaction_type === 0 || get_transaction.transaction_type === 2 || get_transaction.transaction_type === 3 || get_transaction.transaction_type === 13 ?
        <button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('AdminTransaction.Consult_invoice')}</button>
    :
    get_transaction.transaction_type === 1 ?
        <button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('AdminTransaction.Consult_file')}</button>
        :
    get_transaction.transaction_type === 8 ?
    <button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('AdminTransaction.Consult_receipt')}</button>
    :
    t('AdminTransaction.No_action') }
      </td>
    </tr>
  );
})}

  </tbody>
</table>
</div>
</div>


<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 

<table>

{ sortedTransactions.length > 0 ? <Pagination usersPerPage={transactionsPerPage} totalUsers={all_transactions && sortedTransactions.length > 0 ? sortedTransactions.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>

  </Fragment>;





      const tables =
      
      <Fragment>
       <SponsorshipActionsAdmin />
      
     
    { all_transactions !== null ? (
 
<div>

{showTable}

</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      


function findByCriteria (name) {

  if ( searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}







return loading && all_transactions === null ? (
  <Spinner />
) : (
  
  <Fragment>

  {tables}



</Fragment>
);
};


TransactionAdmin.propTypes = {
  getAllTransactions: PropTypes.func.isRequired,
  getInvoice: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  transaction: state.transaction,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAllTransactions, getInvoice, getSponsorships, getAllUsers }
)(TransactionAdmin);


