import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from './../layout/Spinner';
import AdvertisementsActions from './AdvertisementsActions';
import { getSponsorships } from './../../actions/sponsorship';
import { getAdvertisementForClients, updateAdvertisement, publishAdvertisements } from './../../actions/advertisement';
import { addAdvertMessage } from './../../actions/chat';
import pdfIcon from '../../img/pdf.png';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from './../layout/Pagination';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const Advertisements = ({ getAdvertisementForClients, getSponsorships, updateAdvertisement, publishAdvertisements, addAdvertMessage, auth: { user, theme }, advertisement: { users_advertisements }, sponsorship: { loading } }) => {


    const location = useLocation();
    let history = useHistory();

    const [modalsSecondOpen, setModalSecondIsOpen] = useState(false);
    const [modalsThirdOpen, setModalThirdIsOpen] = useState(false);
    const [modalsFourthOpen, setModalFourthIsOpen] = useState(false);
    const [modalsSendMessageOpen, setModalSendMessageIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFirstButton, setModalFirstButton] = useState("");
    const [modalSecondButton, setModalSecondButton] = useState("");
    const [advertisementId, setAdvertisementId] = useState("");
    const [myAdvertisement, setMyAdvertisement] = useState("");
    const [modalsDetailOpen, setModalDetailIsOpen] = useState(false);
    const [modalsPublishApprovedAdvertisementsOpen, setModalPublishApprovedAdvertisementsIsOpen] = useState(false);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [advertisementsPerPage] = useState(10);
  
    const [criteria, setCriteria] = useState("All");
    const [searchCriteria, setSearchCriteria] = useState("Title");
    const [searchField, setSearchField] = useState("");  
  
    const [myAdvertisementsChange, setMyAdvertisementsChange] = useState(false);

    const [advertisementImages, setAdvertisementImages] = useState([]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isZooming, setIsZooming] = useState(false);
    const [zoomStyle, setZoomStyle] = useState({});

    const [category, setCategory] = useState(/*location.state && location.state.category ? location.state.category : */"");
    const [categoryLabel, setCategoryLabel] = useState(/*location.state && location.state.category ? ('{"index": "'+location.state.category+'"}') : */null);

    const [country, setCountry] = useState("");
    const [countryLabel, setCountryLabel] = useState("");

    const [myMessage, setMyMessage] = useState("");
    
  
    useEffect(() => {
        getAdvertisementForClients(user._id);
    }, [getAdvertisementForClients, user._id]);
  
    useEffect(() => {
      getSponsorships(user._id);
    }, [getSponsorships, user._id]);
  
  
    const { t } = useTranslation();

  
    const categories = {
      Home: [
   //     { value: '{"index": "All_home"}', label: t('Advertisements.All_home') },
        { value: '{"index": "Furnishings"}', label: t('Advertisements.Furnishings') },
        { value: '{"index": "Appliances"}', label: t('Advertisements.Appliances') },
        { value: '{"index": "DIY"}', label: t('Advertisements.DIY') },
        { value: '{"index": "Gardening"}', label: t('Advertisements.Gardening') },
        { value: '{"index": "Household_linen"}', label: t('Advertisements.Household_linen') },
        { value: '{"index": "Tableware"}', label: t('Advertisements.Tableware') },
        { value: '{"index": "Decoration"}', label: t('Advertisements.Decoration') },
      ],
      Multimedia: [
   //     { value: '{"index": "All_multimedia"}', label: t('Advertisements.All_multimedia') },
        { value: '{"index": "Computers"}', label: t('Advertisements.Computers') },
        { value: '{"index": "Telephony"}', label: t('Advertisements.Telephony') },
        { value: '{"index": "Image_sound"}', label: t('Advertisements.Image_sound') },
        { value: '{"index": "Console_video_games"}', label: t('Advertisements.Console_video_games') },
        { value: '{"index": "Accessories_peripherals"}', label: t('Advertisements.Accessories_peripherals') },
      ],
      Fashion: [
    //    { value: '{"index": "All_fashion"}', label: t('Advertisements.All_fashion') },
        { value: '{"index": "Clothing"}', label: t('Advertisements.Clothing') },
        { value: '{"index": "Shoes"}', label: t('Advertisements.Shoes') },
        { value: '{"index": "Watches_jewelry"}', label: t('Advertisements.Watches_jewelry') },
        { value: '{"index": "Accessories_luggage"}', label: t('Advertisements.Accessories_luggage') },
        { value: '{"index": "Baby"}', label: t('Advertisements.Baby') },
      ],
      Sports_leisure: [
   //     { value: '{"index": "All_sports_leisure"}', label: t('Advertisements.All_sports_leisure') },
        { value: '{"index": "Sports_equipment"}', label: t('Advertisements.Sports_equipment') },
        { value: '{"index": "Bikes"}', label: t('Advertisements.Bikes') },
        { value: '{"index": "Musical_instruments"}', label: t('Advertisements.Musical_instruments') },
        { value: '{"index": "Games_toys"}', label: t('Advertisements.Games_toys') },
        { value: '{"index": "Collection"}', label: t('Advertisements.Collection') },
      ],
      Vehicle: [
   //     { value: '{"index": "All_vehicles"}', label: t('Advertisements.All_vehicles') },
        { value: '{"index": "Cars"}', label: t('Advertisements.Cars') },
        { value: '{"index": "Utilities"}', label: t('Advertisements.Utilities') },
        { value: '{"index": "Motorcycles"}', label: t('Advertisements.Motorcycles') },
        { value: '{"index": "Caravanning"}', label: t('Advertisements.Caravanning') },
        { value: '{"index": "Boating"}', label: t('Advertisements.Boating') },
      ],
      Others: [
    //    { value: '{"index": "All_others"}', label: t('Advertisements.All_others') },
        { value: '{"index": "Services"}', label: t('Advertisements.Services') },
        { value: '{"index": "Pets"}', label: t('Advertisements.Pets') },
        { value: '{"index": "Donations"}', label: t('Advertisements.Donations') },
        { value: '{"index": "Sharing"}', label: t('Advertisements.Sharing') },
        { value: '{"index": "Miscellaneous"}', label: t('Advertisements.Miscellaneous') },
      ],
    };

      const [subCategories, setSubCategories] = useState(/*location.state && location.state.category ? categories[category] : */[]);
      const [subCategory, setSubCategory] = useState(/*location.state && location.state.subCategory ? location.state.subCategory : */"");
      const [subCategoryLabel, setSubCategoryLabel] = useState(/*location.state && location.state.subCategory ? ('{"index": "'+location.state.subCategory+'"}') : null*/);
  
    var numberCounter = 0;
  
    const indexOfLastAdvertisement = currentPage * advertisementsPerPage;
    const indexOfFirstAdvertisement = indexOfLastAdvertisement - advertisementsPerPage;
    var currentAdvertisements = [];
    var sortedAdvertisements = [];
    if (users_advertisements && users_advertisements.length > 0) {
      let tempData = [...users_advertisements];
      for (var i = 0; i < tempData.length; i++) {
  
        if (i > 0 && (tempData[i - 1].date_creation !== tempData[i].date_creation)) {
          numberCounter = 0;
        }
        else {
          if (i > 0 && ((criteria === "All") || (criteria === "Pending" && tempData[i - 1].state === 1)
          || (criteria === "Approved" && tempData[i - 1].state === 2)
          || (criteria === "Online" && tempData[i - 1].state === 3)
          || (criteria === "Suspended" && tempData[i - 1].state === 4)
          || (criteria === "Deleted" && tempData[i - 1].state === 5)
          )) {
              numberCounter ++;
          }
        }
        tempData[i].number = numberCounter + 1;
  
        if (!myAdvertisementsChange || (myAdvertisementsChange === true && tempData[i].user === user._id)) {
          if (country === "" || tempData[i].country === country) {
          if (category === "" || tempData[i].category === category) {
                if (subCategory === "" || tempData[i].sub_category === subCategory) {
            if ((criteria === "All") || (criteria === "Pending" && tempData[i].status === 1)
                || (criteria === "Approved" && tempData[i].status === 2)
                || (criteria === "Online" && tempData[i].status === 3)
                || (criteria === "Suspended" && tempData[i].status === 4)
                || (criteria === "Deleted" && tempData[i].status === 5))
            {
        if (searchField) {
             if (searchCriteria === "Presenter") {
          if (tempData[i].presenter.toUpperCase().startsWith(searchField.toUpperCase())) {
              sortedAdvertisements.push(tempData[i]);
          }
        }
          else if (searchCriteria === "Title") {
            if (tempData[i].title.toUpperCase() === "") {
              if (t('Language.Lang') === "Français") {
                if ("Aucun".toUpperCase().startsWith(searchField.toUpperCase())) {
                  sortedAdvertisements.push(tempData[i]);
              }
              }
              else if (t('Language.Lang') === "Italiano") {
                if ("Nessuna".toUpperCase().startsWith(searchField.toUpperCase())) {
                  sortedAdvertisements.push(tempData[i]);
              }
              }
              else {
                if ("None".toUpperCase().startsWith(searchField.toUpperCase())) {
                  sortedAdvertisements.push(tempData[i]);
              }
              }
            }
            else {
              if (tempData[i].title.toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAdvertisements.push(tempData[i]);
            }
            }
          }
          else if (searchCriteria === "City") {
            if (tempData[i].city.toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAdvertisements.push(tempData[i]);
            }
          }

          else if (searchCriteria === "Postal_code") {
            if (tempData[i].postal_code.toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAdvertisements.push(tempData[i]);
            }
          }
        else if (searchCriteria === "D") {
          var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
          var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
            if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
              finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
            }
  
        if (finalDateDay.startsWith(searchField)) {
          sortedAdvertisements.push(tempData[i]);
        }
      }
      else if (searchCriteria === "M") {
        var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
        
          dateMonth = dateMonth[1] + "-" + dateMonth[0]
       
  
      if (dateMonth.startsWith(searchField)) {
          sortedAdvertisements.push(tempData[i]);
      }
    }
    else if (searchCriteria === "Y") {
  
      var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]
  
  
    if (dateYear.startsWith(searchField)) {
      sortedAdvertisements.push(tempData[i]);
    }
  }
        }
        else {
          sortedAdvertisements.push(tempData[i]);
        }
      }
      }
    }
  }
}
}
  
      currentAdvertisements = sortedAdvertisements.reverse().slice(indexOfFirstAdvertisement, indexOfLastAdvertisement);
    }
  
  
    const paginate = pageNum => setCurrentPage(pageNum);
  
    const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedAdvertisements.length / advertisementsPerPage) ? currentPage : currentPage + 1);
  
    const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  
    const firstPage = () => setCurrentPage(1);
  
    const lastPage = () => setCurrentPage(Math.ceil(sortedAdvertisements.length / advertisementsPerPage));
  
  
  
    function handleCriteriaChange (changeEvent) {
        if(changeEvent.currentTarget.value === "All") {
            setMyAdvertisementsChange(false)
        }
        else {
            setMyAdvertisementsChange(true)
        }
      setCriteria(changeEvent.currentTarget.value);
      setCurrentPage(1);
  }
  
  function handleSearchCriteriaChange (changeEvent) {
  
    if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
      setSearchField("");
    }
    else if ((changeEvent.currentTarget.value === "Presenter" || changeEvent.currentTarget.value === "Title" || changeEvent.currentTarget.value === "City" || changeEvent.currentTarget.value === "Postal_code") && !isNaN(searchField)) {
      setSearchField("");
    }
  
    setSearchCriteria(changeEvent.currentTarget.value);
  
    setCurrentPage(1);
  }
  
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % advertisementImages.length);
  };
  
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? advertisementImages.length - 1 : prevIndex - 1
    );
  };
  
  const handleZoom = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
  
    setZoomStyle({
      transformOrigin: `${x}% ${y}%`,
      transform: "scale(2)", // Ajustez la valeur du zoom ici
    });
    setIsZooming(true);
  };
  
  const resetZoom = () => {
    setIsZooming(false);
    setZoomStyle({});
  };

  function handleMyAdvertisementsChange () {
    setMyAdvertisementsChange(!myAdvertisementsChange);
    setCurrentPage(1);
  }
        
  
  
        const showTable =
        
        
        <Fragment>
        
        <br/><br/>
  <h1 className='large text-primary'>{t('Advertisements.Advertisement_list')}</h1>
  
  
    
  <div
   style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center"
   }}>
  
  <div className="scrollme">
  
  <div className='form'>
  <div className="form-group">
            <input
              type='text'
              placeholder={t('Advertisements.Search_by') + " " + (searchCriteria === "Presenter" ? t('Advertisements.Presenter').toLowerCase() : searchCriteria === "Title" ? t('Advertisements.Title').toLowerCase() : searchCriteria === "City" ? t('Advertisements.City_locality').toLowerCase() : searchCriteria === "Postal_code" ? t('Advertisements.Postal_code').toLowerCase() : searchCriteria === "D" ? t('Advertisements.Day_text').toLowerCase() : searchCriteria === "M" ? t('Advertisements.Month_text').toLowerCase() : searchCriteria === "Y" ? t('Advertisements.Year_text').toLowerCase() : "")}
              name='searchField'
              value={searchField}
              onChange={e => findByCriteria(e.target.value)}
            />
          </div>
  </div>
  
  
  <div className="form-group" style={{
     display: "flex",
     alignItems: "center" }}>
           <h4>
      {t('Advertisements.Search_by')}:
            </h4>
          <label style={{marginRight:"20px", marginTop: "10px"}}>
  
        </label>
  
  
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Presenter" 
           id="presenter"
           className="custom-control-input"
                        checked={searchCriteria === 'Presenter'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="presenter">
            {t('Advertisements.Presenter')}
        </label>
        </div>
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Title" 
           id="title"
           className="custom-control-input"
                        checked={searchCriteria === 'Title'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="title">
            {t('Advertisements.Title')}
        </label>
        </div>
  
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="City" 
           id="city"
           className="custom-control-input"
                        checked={searchCriteria === 'City'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="city">
            {t('Advertisements.City_locality')}
        </label>
        </div>
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Postal_code" 
          id="postal_code"
          className="custom-control-input"
                        checked={searchCriteria === 'Postal_code'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="postal_code">
            {t('Advertisements.Postal_code')}
        </label>
        </div>
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="D" 
           id="day"
           className="custom-control-input"
                        checked={searchCriteria === 'D'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="day">
            {t('Advertisements.Day')}
        </label>
        </div>
        
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="M" 
           id="month"
           className="custom-control-input"
                        checked={searchCriteria === 'M'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="month">
            {t('Advertisements.Month')}
        </label>
        </div>
  
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Y" 
           id="year"
           className="custom-control-input"
                        checked={searchCriteria === 'Y'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="year">
            {t('Advertisements.Year')}
        </label>
        </div>
  
      </div>
  
  <div className="form-group" style={{
     display: "flex",
     alignItems: "center" }}>
           <h4>
      {t('Advertisements.State')}:
            </h4>
          <label style={{marginRight:"20px", marginTop: "10px"}}>
  
        </label>
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="All" 
           id="all"
           className="custom-control-input"
                        checked={criteria === 'All'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="all">
            {t('Advertisements.All')}
        </label>
        </div>
  
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Pending" 
           id="pending"
           className="custom-control-input"
                        checked={criteria === 'Pending'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="pending">
            {t('Advertisements.Pending')}
        </label>
        </div>
  
    
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Approved" 
           id="approved"
           className="custom-control-input"
                        checked={criteria === 'Approved'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="approved">
            {t('Advertisements.Approved')}
        </label>
        </div>

        <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Online" 
         id="online"
         className="custom-control-input"
                      checked={criteria === 'Online'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="online">
          {t('Advertisements.Online')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Suspended" 
         id="suspended"
         className="custom-control-input"
                      checked={criteria === 'Suspended'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="suspended">
          {t('Advertisements.Suspended')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Deleted" 
         id="deleted"
         className="custom-control-input"
                      checked={criteria === 'Deleted'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="deleted">
          {t('Advertisements.Deleted')}
      </label>
      </div>
  
      </div>
  
      <div className="form-group" style={{
     display: "flex",
     alignItems: "center" }}>
           <h4>
      {t('Advertisements.Other_criteria')}:
            </h4>
          <label style={{marginRight:"20px", marginTop: "10px"}}>
  
        </label>
  
  
        <div className="custom-control custom-checkbox custom-control-inline">
          <input type="checkbox"
           id="my_advertisements"
           className="custom-control-input"
                        checked={myAdvertisementsChange === true} 
                        onChange={handleMyAdvertisementsChange} />
                        <label className="custom-control-label" for="my_advertisements">
            {t('Advertisements.My_advertisements')}
        </label>
        </div>

      </div>


      <div className="form-group" style={{
     display: "flex",
     alignItems: "center" }}>
           <h4>
      {t('Advertisements.Filter')}:
            </h4>
          <label style={{marginRight:"20px", marginTop: "10px"}}>
  
        </label>
  

        <div className='form-group px-2'>
<select className="form-control" name="action" value={categoryLabel} onChange={e => dropDownSelectedCategory(e.target.value)}>
<option value="">{t('Advertisements.Category')+ " ("+ t('Advertisements.All') + ")"}</option>
             <option value= {'{"index": "Home"}'}>{t('Advertisements.Home')}</option>
             <option value= {'{"index": "Multimedia"}'}>{t('Advertisements.Multimedia')}</option>
             <option value= {'{"index": "Fashion"}'}>{t('Advertisements.Fashion')}</option>
             <option value= {'{"index": "Sports_leisure"}'}>{t('Advertisements.Sports_leisure')}</option>
             <option value= {'{"index": "Vehicle"}'}>{t('Advertisements.Vehicles')}</option>
             <option value= {'{"index": "Others"}'}>{t('Advertisements.Others')}</option>
           </select>
        </div>


        <div className='form-group px-2'>
        <select className="form-control" name="action" value={subCategoryLabel} onChange={e => dropDownSelectedSubCategory(e.target.value)}>
            {
              //<option value="">{t('Advertisements.Sub_category')+ " ("+ t('Advertisements.All') + ")"}</option>
              category === "Home" ?
              <option value="">{t('Advertisements.All_home')}</option> :
              category === "Multimedia" ?
              <option value="">{t('Advertisements.All_multimedia')}</option> :
              category === "Fashion" ?
              <option value="">{t('Advertisements.All_fashion')}</option> :
              category === "Sports_leisure" ?
              <option value="">{t('Advertisements.All_sports_leisure')}</option> :
              category === "Vehicles" ?
              <option value="">{t('Advertisements.All_vehicles')}</option> :
              category === "Others" ?
              <option value="">{t('Advertisements.All_others')}</option> :
              <option value="">{t('Advertisements.Sub_category')+ " ("+ t('Advertisements.All') + ")"}</option>
            }
            {
            subCategories.map((subCategory) => (
            <option key={subCategory.value} value={subCategory.value}>
              {subCategory.label}
              </option>
              ))}
           </select>
        </div>


      <div className='form-group px-2'>
<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelectedCountry(e.target.value)}>
<option value="">{t('Purchases.Select_country')}</option>
            <option value= {'{"index": ""}'}>{t('Advertisements.All')}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
             <option value= {'{"index": "GP"}'}>{t('Country.GP')}</option>
             <option value= {'{"index": "GF"}'}>{t('Country.GF')}</option>
             <option value= {'{"index": "MQ"}'}>{t('Country.MQ')}</option>
             <option value= {'{"index": "RE"}'}>{t('Country.RE')}</option>
           </select>
        </div>
  
        </div>
  
    
  <table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
    <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
    <tr>
    <th scope="col">{t('Advertisements.Number')}</th>
    <th scope="col">{t('Advertisements.Date')}</th>
    <th scope="col">{t('Advertisements.Image')}</th>
    <th scope="col">{t('Advertisements.Presenter')}</th>
    <th scope="col">{t('Advertisements.Title')}</th>
    <th scope="col">{t('Advertisements.Amount')}</th>
      <th scope="col">{t('Advertisements.Condition')}</th>
      <th scope="col">{t('Advertisements.City_locality')}</th>
      <th scope="col">{t('Advertisements.Postal_code')}</th>
      <th scope="col">{t('Advertisements.Country')}</th>
      <th scope="col">{t('Advertisements.State')}</th>
      <th scope="col">{t('Advertisements.Actions')}</th>
      </tr>
    </thead>
    <tbody>
  
  
    {!users_advertisements  ? 
            <tr>
            <th colSpan={12}>{t('Advertisements.Calculating') + " ..."}</th>
          </tr>
        :
        sortedAdvertisements.length === 0 ?
        <tr>
            <th colSpan={12}>{t('Advertisements.No_advertisements')}</th>
          </tr>
         :
        currentAdvertisements.map(( get_advertisement, index ) => {
     // console.log("sponsorships"+JSON.stringify(sponsorship))
  
       return (
      <tr key={index}>
      <td>{sortedAdvertisements.length - index}</td>

  
      <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_advertisement.date_creation.substring(0, 10).split("-")[2] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[1] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[0] : get_advertisement.date_creation.substring(0, 10).split("-")[1] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[2] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[0]}</td>
      <td><div style={{width: 50, height: 'auto', display: get_advertisement.attachment_name_first.split('.').pop() === "pdf" ? "block" : "none"}}>
       <a href={get_advertisement.attachment_name_first} target="_blank"  rel="noopener noreferrer"><img border="0" alt="pdf" src={pdfIcon} /></a></div>
       <a href={get_advertisement.attachment_name_first} target="_blank"  rel="noopener noreferrer"><img src={get_advertisement.attachment_name_first} alt="" className="rounded-lg" style = {{ display: get_advertisement.attachment_name_first.split('.').pop() !== "pdf" ? 'block' : "none",
        maxWidth:'75px',
        maxHeight:'75px',
        width: 'auto',
        height: 'auto'}}/></a></td>
      <td>{get_advertisement.presenter}</td>
      <td>{get_advertisement.title}</td>
      <td>{get_advertisement.selling_price.toFixed(2) + "€"}</td>
      <td>{t(`Advertisements.${get_advertisement.condition}`)}</td>
      <td>{get_advertisement.city}</td>
      <td>{get_advertisement.postal_code}</td>
      <td>{t(`Country.${get_advertisement.country}`)}</td>
      <td>
    {(get_advertisement.status === 1 ? t('Advertisements.Pending') :
    get_advertisement.status === 2 ? t('Advertisements.Approved') :
    get_advertisement.status === 3 ? t('Advertisements.Online') :
    get_advertisement.status === 4 ? t('Advertisements.Suspended') :
      get_advertisement.status === 5 ? t('Advertisements.Deleted') : "" )}</td>
        <td>
          {get_advertisement.status === 3 && get_advertisement.user === user._id ? 
               <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Advertisements.Select_action')}</option>
               <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
               <option value= {'{"index": "4", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Suspend')}</option>
               <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
             </select>
             :
             get_advertisement.status === 4 && get_advertisement.user === user._id ? 
               <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Advertisements.Select_action')}</option>
               <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
               <option value= {'{"index": "3", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Publish')}</option>
               <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
             </select>
             : get_advertisement.user === user._id && get_advertisement.status !== 5 ?
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Advertisements.Select_action')}</option>
               <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
               <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
             </select>
             :
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Advertisements.Select_action')}</option>
               <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
               <option value= {'{"index": "6", "advertisement": {"_id": "' + get_advertisement._id + '", "user": "' + get_advertisement.user + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "condition": "' + get_advertisement.condition + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Send_message')}</option>
             </select>
             }
        </td>
      </tr>
    );
  })}
  
    </tbody>
  </table>
  </div>
  </div>
  
  
  <div
   style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center"
   }}>
  <div className="scrollme"> 
  <table>
  { sortedAdvertisements.length > 0 ? <Pagination usersPerPage={advertisementsPerPage} totalUsers={users_advertisements && sortedAdvertisements.length > 0 ? sortedAdvertisements.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
  </table>
  </div>
  </div>
    </Fragment>;
  
  
  
  const showModalPublish = 
  <Modal className="my-modal" show={modalsSecondOpen} onHide={() => setModalSecondIsOpen(false)} as="section">
  <ModalHeader closeButton as="span">
    <ModalTitle as="h4">{modalTitle}</ModalTitle>
  </ModalHeader>
  <ModalBody as="section" 
   style={{
    whiteSpace: "pre-line"
   }}>{modalBody}</ModalBody>
  <ModalFooter as="footer">
  
    {modalFirstButton !== "" ? 
    <>
  <button className="btn btn-primary" onClick={() => publishButtonClicked()}>{modalFirstButton}</button>
  </> :
  null
    }
      {modalSecondButton !== "" ? 
      
  <button className="btn btn-primary" onClick={() => setModalSecondIsOpen(false)}>{modalSecondButton}</button>
  
   :
   null
    }
  
  </ModalFooter>
  </Modal>
  
  
  
  const showModalSuspend = 
<Modal className="my-modal" show={modalsThirdOpen} onHide={() => setModalThirdIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => suspendButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalThirdIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalDelete = 
<Modal className="my-modal" show={modalsFourthOpen} onHide={() => setModalFourthIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalFourthIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalDetail = 
<Modal className="my-modal" show={modalsDetailOpen} onHide={() => setModalDetailIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>  {advertisementImages.length > 0 ? (
  <div className="carousel-container">
    <div className="carousel-wrapper">
      <div className="carousel-slides">
      <div
                        className="carousel-item-wrapper"
                        onMouseMove={handleZoom}
                        onMouseLeave={resetZoom}
                      >
                        <img
                          src={advertisementImages[currentIndex]}
                          alt={`Image ${currentIndex + 1}`}
                          onClick={() => window.open(advertisementImages[currentIndex], "_blank")}
                          className={`carousel-image ${isZooming ? "zoomed" : ""}`}
                          style={zoomStyle}
                        />
                      </div>
      </div>
    <button
      className="carousel-control prev"
      onClick={handlePrev}
    >
      &#10094;
    </button>
    <button
      className="carousel-control next"
      onClick={handleNext}
    >
      &#10095;
    </button>
  </div>
  </div>
) : (
  <></>
)}
<br/>
{modalBody}<div/></ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>


const showModalPublishApprovedAdvertisements = 
<Modal className="my-modal" show={modalsPublishApprovedAdvertisementsOpen} onHide={() => setModalPublishApprovedAdvertisementsIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => publishAdvertisementsClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalPublishApprovedAdvertisementsIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showSendMessageModal = 
<Modal className="my-modal" show={modalsSendMessageOpen} onHide={() => setModalSendMessageIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}
      <form className="form">
        <div className="form-group">
          <input
            type="text"
            placeholder={t('Advertisements.My_message')}
            name="myMessage"
            value={myMessage}
            onChange={e => setMyMessage(e.target.value)}
            minLength="1"
          />
        </div>
      </form>
      </ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => sendMessageButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalSendMessageIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



        const tables =
        
        <Fragment>
        <AdvertisementsActions />
        
       
      { users_advertisements !== null ? (
   
  <div>
  
  {showTable}
  
  </div>
      ) : (
  <Fragment>
  </Fragment>
      )}
    </Fragment>;
  
        
  
  
  function findByCriteria (name) {
  
    if ( searchCriteria === "Y") {
      setSearchField(name.replace(/\D/,''));
    }
    else if (searchCriteria === "D" || searchCriteria === "M") {
      setSearchField(name.replace(/(?!-)[^0-9.]/,''));
    }
    else {
      setSearchField(name);
    }
  
  
    if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
      setSearchField(name + "-")
  }
  else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
    setSearchField(name + "-")
  }
  
  
    setCurrentPage(1);
  }
  

  function onClickPublishItem(newAdvertisement) {
    setModalTitle(t('Advertisements.Publish_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
    setModalBody(
      t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
      JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
      "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
      t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
     t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
    );
    setModalFirstButton(t('Advertisements.Publish'));
    setModalSecondButton("");
   setModalSecondIsOpen(true);
}
  

function onClickSuspendItem(newAdvertisement) {
  setModalTitle(t('Advertisements.Suspend_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
  setModalBody(
    t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
    JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
    "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
    t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
   "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
   t('Advertisements.'+ JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
   "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
  );
  setModalFirstButton(t('Advertisements.Suspend'));
  setModalSecondButton("");
 setModalThirdIsOpen(true);
}

function onClickDeleteItem(newAdvertisement) {
    setModalTitle(t('Advertisements.Delete_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
    setModalBody(
      t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
      JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
      "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
      t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
     t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
    );
    setModalFirstButton(t('Advertisements.Delete'));
    setModalSecondButton("");
   setModalFourthIsOpen(true);
  }

  function onClickSendMessageItem(newAdvertisement) {
    setMyAdvertisement({user: JSON.parse(JSON.stringify(newAdvertisement.user)),
        advertId: JSON.parse(JSON.stringify(newAdvertisement._id)),
            title: JSON.parse(JSON.stringify(newAdvertisement.title)),
            receiverUsername: JSON.parse(JSON.stringify(newAdvertisement.presenter)),
    })
    setModalTitle(t('Advertisements.Interested_article')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
    setModalBody(t('Advertisements.Enter_message'));
    setModalFirstButton(t('Advertisements.Send'));
    setModalSecondButton("");
    setModalSendMessageIsOpen(true);
  }


  
function showDetailsTable(advertisement) {
    var dateCreation = JSON.parse(JSON.stringify(advertisement.date_creation))
    var finalDateCreation = ""
    if (dateCreation !== "") {
      dateCreation = JSON.parse(JSON.stringify(advertisement.date_creation)).substring(0, 10).split("-")
      finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
    
      if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
        finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
      }
    }
    setCurrentIndex(0);
    setAdvertisementImages([advertisement.attachment_name_first, advertisement.attachment_name_second, advertisement.attachment_name_third].filter(image => image !== ""))
    
    setModalTitle(JSON.parse(JSON.stringify(advertisement.title)));
    setModalBody(
      t('Advertisements.Presenter')+" : " + JSON.parse(JSON.stringify(advertisement.presenter))  +
      "\n"+t('Advertisements.Description')+" : " + JSON.parse(JSON.stringify(advertisement.description))  +
      "\n"+t('Advertisements.Further_information')+" : " + (JSON.parse(JSON.stringify(advertisement.further_information)) !== '' ?
      JSON.parse(JSON.stringify(advertisement.further_information)) : t('Advertisements.None')) +
      "\n"+t('Advertisements.Condition')+" : " + t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.condition))) +
      "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(advertisement.category)) !== '' ?
    t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.category))) : t('Advertisements.None')) +
    "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(advertisement.sub_category)) !== '' ?
    t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.sub_category))) : t('Advertisements.None')) +
    "\n"+t('Advertisements.Selling_price')+" : " + (JSON.parse(JSON.stringify(advertisement.selling_price)) !== '' ?
    JSON.parse(JSON.stringify(advertisement.selling_price)) + "€" : t('Advertisements.None'))  +
    "\n"+t('Advertisements.Delivery_method')+" : " + t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.delivery_method)))  +
    "\n"+t('Advertisements.City_locality')+" : " + (JSON.parse(JSON.stringify(advertisement.city)) !== '' ?
    JSON.parse(JSON.stringify(advertisement.city)) : t('Advertisements.None')) +
    "\n"+t('Advertisements.Postal_code')+" : " + (JSON.parse(JSON.stringify(advertisement.postal_code)) !== '' ?
    JSON.parse(JSON.stringify(advertisement.postal_code)) : t('Advertisements.None')) +
    "\n"+t('Advertisements.Country')+" : " + t('Country.'+JSON.parse(JSON.stringify(advertisement.country)))  +
    "\n"+t('Advertisements.State')+" : " + (JSON.parse(JSON.stringify(advertisement.status)) === "1" ? t('Advertisements.Pending') :
    JSON.parse(JSON.stringify(advertisement.status)) === "2" ? t('Advertisements.Approved') :
    JSON.parse(JSON.stringify(advertisement.status)) === "3" ? t('Advertisements.Online') :
    JSON.parse(JSON.stringify(advertisement.status)) === "4" ? t('Advertisements.Suspended') :
    JSON.parse(JSON.stringify(advertisement.status)) === "5" ? t('Advertisements.Deleted') : t('Advertisements.None')) +
    (advertisement.date_creation !== "" ? ("\n"+t('Advertisements.Date')+" : "  + finalDateCreation) : "")
    );
      setModalFirstButton("");
      setModalSecondButton(t('Advertisements.Ok'));
     setModalDetailIsOpen(true);
    }
  
  
    function publishButtonClicked() {
        updateAdvertisement(advertisementId, 3, user._id, false);
        setModalSecondIsOpen(false);
    }
    
    function suspendButtonClicked() {
        updateAdvertisement(advertisementId, 4, user._id, false);
        setModalThirdIsOpen(false);
    }
    
    function deleteButtonClicked() {
        updateAdvertisement(advertisementId, 5, user._id, false);
        setModalFourthIsOpen(false);
    }
    
    function publishAdvertisementsClicked() {
      publishAdvertisements(user._id);
      setModalPublishApprovedAdvertisementsIsOpen(false);
    }

    function sendMessageButtonClicked() {
        addAdvertMessage({senderId: user._id,
            receiverId: myAdvertisement.user,
                advertId: myAdvertisement.advertId,
                    category: "Advertisement",
                        title: myAdvertisement.title,
                            senderUsername: user.username,
                            senderAdminUsername: "",
                                receiverUsername: myAdvertisement.receiverUsername,
                                    text: myMessage,
                                    history : history
        })
        setModalSendMessageIsOpen(false);
      }
  
  
  const dropDownSelected = event => {
    if (JSON.parse(event).index === "1") {
      showDetailsTable(JSON.parse(event).advertisement);
    }
    else if (JSON.parse(event).index === "3") {
      setAdvertisementId(JSON.parse(event).advertisement._id)
      onClickPublishItem(JSON.parse(event).advertisement);
    }
    else if (JSON.parse(event).index === "4") {
      setAdvertisementId(JSON.parse(event).advertisement._id)
      onClickSuspendItem(JSON.parse(event).advertisement);
    }
    else if (JSON.parse(event).index === "5") {
      setAdvertisementId(JSON.parse(event).advertisement._id)
      onClickDeleteItem(JSON.parse(event).advertisement);
    }
    else if (JSON.parse(event).index === "6") {
      setAdvertisementId(JSON.parse(event).advertisement._id)
      onClickSendMessageItem(JSON.parse(event).advertisement);
    }
  }

  function selectCategory (val) {
    setCategory(JSON.parse(val).index);
    setCategoryLabel(val);
  }

function selectSubCategory (val) {
  setSubCategory(JSON.parse(val).index);
  setSubCategoryLabel(val);
}

const dropDownSelectedCategory = event => {
    if (event && JSON.parse(event).index) {
    selectCategory(event)
    setSubCategories(categories[JSON.parse(event).index] || []);
    setSubCategory("");
    setSubCategoryLabel(null);
    }
    else {
        setCategory("");
        setCategoryLabel();
        setSubCategories([]);
        setSubCategory("");
        setSubCategoryLabel();
        setSubCategoryLabel([]);
    }
  }

  const dropDownSelectedSubCategory = event => {
    if (event && JSON.parse(event).index) {
    selectSubCategory(event)
    }
    else {
     //   setCategory("");
     //   setCategoryLabel();
    //    setSubCategories([]);
        setSubCategory("");
        setSubCategoryLabel();
    //    setSubCategoryLabel([]);
    }
  }


  const dropDownSelectedCountry = event => {
    if (event && JSON.parse(event).index) {
      setCountry(JSON.parse(event).index);
      setCountryLabel(event);
    }
    else {
      setCountry("");
      setCountryLabel("");
    }
  }
  
  
  
  
  return loading && users_advertisements === null ? (
    <Spinner />
  ) : (
    
    <Fragment>
      {/*  <section className="container">
          <h1 className='x-large text-primary'>
            <i className='fas fa-exclamation-triangle' /> {t('Advertisements.Good_deals')}
          </h1>
          <p className='large'>{t('NavBar.Wip')}</p>
          <div className="progress">
      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}></div>
    </div>
          </section> */}
  
    {showModalPublish}
    {showModalSuspend}
    {showModalDelete}
    {showModalDetail}
    {showModalPublishApprovedAdvertisements}
    {showSendMessageModal}
    {tables}
  
  
  
  </Fragment>
  );
  };
  
  
  Advertisements.propTypes = {
    getAdvertisementForClients: PropTypes.func.isRequired,
    updateAdvertisement: PropTypes.func.isRequired,
    publishAdvertisements: PropTypes.func.isRequired,
    addAdvertMessage: PropTypes.func.isRequired,
    sponsorship: PropTypes.object.isRequired,
    advertisement: PropTypes.object.isRequired,
    getSponsorships: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    advertisement: state.advertisement,
    auth: state.auth
  });
  
  export default connect(
    mapStateToProps,
    { getAdvertisementForClients, updateAdvertisement, publishAdvertisements, addAdvertMessage, getSponsorships }
  )(Advertisements);  