import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SponsorshipActionsAdmin = 
({auth: { user }, chat: { chats_for_admin }}) => {
  const { t } = useTranslation();


  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);

  useEffect(() => {
    let newTotalUnreadMessages = 0;
  for (let i = 0; i < chats_for_admin?.length; i++) {
    if (chats_for_admin[i].lastSender === "contact@atrakxion.com") {
      continue;
    }
    newTotalUnreadMessages += chats_for_admin[i].seen;
  }
  setTotalUnreadMessages(newTotalUnreadMessages);
  }, [chats_for_admin, user]) 

  return (
    <div className='dash-buttons'>
      <Link to='/admin/affiliations' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Affiliations')}
      </Link>
      <Link to='/admin/affiliate-program' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Affiliate_program')}
      </Link>
      <Link to='/admin/activities' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Activities')}
      </Link>
      <Link to='/admin/transfers' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Transfers')}
      </Link>
      <Link to='/admin/digital-wallet' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Digital_wallets')}
      </Link>
      <Link to='/admin/charts' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Statistics')}
      </Link>
     {/* <Link to='/admin/articles' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Articles')}
  </Link> */}
      <Link to='/admin/add-article' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Add_article')}
  </Link>
  <Link to='/admin/messaging' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Messaging') + " (" + totalUnreadMessages + ")"}
  </Link>
  <Link to='/admin/message-history' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Message_history')}
  </Link>
  <Link to='/admin/deals' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Deals')}
  </Link>
  <Link to='/admin/purchases' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Purchases')}
  </Link>
  <Link to='/admin/advertisements' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('AdminSponsorship.Member_to_member')}
  </Link>
      <br /><br /><br />
    </div>

  );
};

SponsorshipActionsAdmin.propTypes = {
    sponsorship: PropTypes.object.isRequired,
      auth: PropTypes.object.isRequired,
      chat: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    auth: state.auth,
    chat: state.chat,
  });
  
  export default connect(
    mapStateToProps,
    { }
  )(SponsorshipActionsAdmin);
  